import React, {useEffect, useState} from "react";
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward";

export default function WhyChoseCollegify(props) {
    const [referral, setReferral] = useState("");
    useEffect(() => {
        setTimeout(() => {
            let query = sessionStorage.getItem("referral") ? '?referralCode=' + sessionStorage.getItem("referral") : ""
            setReferral(query);
        }, 300)
    }, []);
    return (
        <section className='pb-5'>
            <div className='container mb-5'>
                <div className='text-center'>
                    <h2 className='mb-3 fs-std fs-sm-std'>Be smart. Choose {process.env.NEXT_PUBLIC_APP_NAME}!</h2>
                    <div className='flex-center flex-column'>
                        {
                            props?.cta ?
                            <a onClick={() => props.cta()}>
                                <button className='btn btn-solid-normal --primary btn-with-icon mb-0'>
                                    Register Now
                                    <span className='btn-icon --primary'>
                                        <MdArrowForward fontSize='20px' />
                                    </span>
                                </button>
                            </a>
                            :
                            <a href={process.env.NEXT_PUBLIC_FRONT_URI + "signup" + referral}>
                                <button className='btn btn-solid-normal --primary btn-with-icon mb-0 bg-secondary'>
                                    Register Now
                                    <span className='btn-icon --primary'>
                                        <MdArrowForward fontSize='20px' />
                                    </span>
                                </button>
                            </a>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
