import React, { Component } from "react";
import lazyframe from "lazyframe";
import WhiteCurveSVG from "../components/WhiteCurveSVG"
import LeadCollectionForm from "../components/LeadCollectionForm"
// import Link from 'next/link';
import ComponentStatus from '../config/component';
import Zoom from 'react-medium-image-zoom'
import ipdata from "../services/ipdata";
import { get } from "../services/Fetch";
import { MdClose } from '@react-icons/all-files/md/MdClose'
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward";
import { MdDone } from "@react-icons/all-files/md/MdDone";
import dynamic from "next/dynamic";
import Layout from "../layouts/Layout";
import SATIndex from "../components/custom/svg/SATIndex";
import ACTIndex from "../components/custom/svg/ACTIndex";
const CustomImage = dynamic(() => import('../components/CustomImage'));
import Link from "next/link";
import SiteLoader from "../components/SiteLoader";
import { NextSeo } from "next-seo";
import WhyChoseCollegify from "../components/WhyChoseCollegify";

let referral_code = "ICICICARDS";

export default class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: {},
            locationDetails: '',
            countryList: [],
            showAd: true,
            consultType: ''
        }
    };

    hideAd = () => this.setState((currentState) => ({showAd: !currentState.showAd}));

    componentDidMount() {

        lazyframe(".lazyframe", {
            debounce: 100,
            lazyload: true,
            autoplay: false,
        });

        ipdata.getLocation().then((res) => {
            if(res) {
                this.setState({locationDetails: JSON.parse(localStorage.getItem('user_location_details_local')).geoLocationDetails});
            }
        }).catch((err) => {
            console.log(err.message);
        });
        this.fetchCountryList();
        
    }

    setQuery = (type) => {
        this.setState({
            ...this.state,
            query: {
                target: type,
                courseId: 1
            }
        }, () => {
            
            document.querySelector('#top-heading').scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        })
    }

    fetchCountryList = () => {
        let input = {
            "status": "active"
        };
        const config = {
            Authorization: `Bearer ${null}`,
        };
        get(`${process.env.NEXT_PUBLIC_API_URI}v1/country/getAll?_format=json&input=${encodeURIComponent(JSON.stringify(input))}`, null, config)
            .then(res => {
                if (res.code === "200" && res.status === "success") {
                    this.setState({
                        countryList: res.data.countries,
                    });
                }
            }).catch(error => {
                console.log(error)
            });
    }

    render() {
        const {locationDetails, countryList, query, consultType, loading } = this.state;
        return (
            <Layout>
                {
                    process.env.NEXT_PUBLIC_ENV === 'production' &&
                    <NextSeo
                        title={'Bespoke Mentoring Services for College Admission'}
                        titleTemplate={`${process.env.NEXT_PUBLIC_APP_NAME} | %s`}
                        copyright={`${process.env.NEXT_PUBLIC_APP_NAME} has copyrights to all the content of this page`}
                        keywords={'digital learning,customised course plan,best learning platform,predictive score,students,parents,educators,sat,act,sat vs act, act vs sat,diagnostic test,free,trial,detailed test reports,sat,act,points improvement,expert faculty, competitor of byjus, competitor of khan academy, competitor of prep scholar'}
                        description={`Your ideal score in less time with a lot more fun using ${process.env.NEXT_PUBLIC_APP_NAME}â€™s cutting-edge data analytics. Discover our market-leading tech, built to empower students, connect parents and enable educators seamlessly.`}
                        openGraph={{
                            url: process.env.NEXT_PUBLIC_APP_URI,
                            locale: 'en_US',
                            title: process.env.NEXT_PUBLIC_APP_NAME + ' | Bespoke Mentoring Services for College Admission',
                            type: 'website',
                            description: `Your ideal score in less time with a lot more fun using ${process.env.NEXT_PUBLIC_APP_NAME}â€™s cutting-edge data analytics. Discover our market-leading tech, built to empower students, connect parents and enable educators seamlessly.`,
                            images: [
                                {
                                    url: process.env.NEXT_PUBLIC_SOCIAL_BANNER === "" ? process.env.NEXT_PUBLIC_FILE_URI + 'social-banner/elearning-landing.png': process.env.NEXT_PUBLIC_SOCIAL_BANNER,
                                    width: 500,
                                    height: 500,
                                    alt: 'Interactive Platform for Adaptive Learning',
                                }
                            ],
                            site_name: process.env.NEXT_PUBLIC_APP_NAME,
                        }}
                    />
                }
                {loading && <SiteLoader />}
                {/* Landing Intro Section */}
                {
                    ComponentStatus.INTRO &&
                    <section className="padded-intro-section nav-margin hero-bg">
                        <div className="container">
                            <div className='row g-3 mx-auto'>
                                <div className="col-xl-6 mb-4 mb-xl-0">
                                    <div>
                                        <h1 className="mb-4 text-center text-xl-start">Conquer Digital-SAT/ACT with {process.env.NEXT_PUBLIC_APP_NAME}!</h1>
                                        <h4 className="mb-4 text-center text-md-center text-xl-start">
                                            {process.env.NEXT_PUBLIC_APP_NAME} uses data analytics to help students reach their
                                        true potential on standardized tests.
                                        </h4>
                                        <ul className="heading-points header">
                                            <li>10K+ Questions</li>
                                            <li>20+ Mocks</li>
                                            <li>400+ Worksheets</li>
                                            <li>250+ Video Lessons</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='col-xl-6 col-md-8 mx-auto'>
                                    {
                                        ComponentStatus.INTRO_VIDEO &&
                                        <div className="lazyframe video-wrapper position-relative mb-4 mx-auto mx-xl-0"
                                            id="intro-video"
                                            data-vendor=""
                                            data-title=""       
                                            data-thumbnail={process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT + '/static/thumbnail-main.jpg?tr=f-auto,dpr-0.5,fo-center'}
                                            data-src={ComponentStatus.INTRO_VIDEO}
                                            data-ratio="16:9"   
                                            data-initinview="false"
                                            data-autoplay="false"
                                            style={{backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}
                                        />
                                    }
                                    <div className="home-btn-wrapper">
                                        {/* <Link href='/course/sat'>
                                            <a>
                                                <button className='home-btn'>
                                                    Explore SAT
                                                <span className='icn-circle big ms-2'><MdArrowForward fontSize='22px'
                                                        className='text-primary' /></span>
                                                </button>
                                            </a>
                                        </Link> */}
                                        <Link href='/course/digital-sat'>
                                            <a>
                                                <button className='home-btn'>
                                                    Explore Digital SAT
                                                <span className='icn-circle big ms-2'><MdArrowForward fontSize='22px'
                                                        className='text-primary' /></span>
                                                </button>
                                            </a>
                                        </Link>
                                        <Link href='/course/act'>
                                            <a>
                                                <button className='home-btn'>
                                                    Explore ACT
                                                <span className='icn-circle big ms-2'><MdArrowForward fontSize='22px'
                                                        className='text-primary' /></span>
                                                </button>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <WhiteCurveSVG />
                    </section>
                }
                {/* 3rd section */}
                <section className="container text-center my-5">
                    <div className="col-md-10 mx-auto">
                        <h3 className="text-center fs-3">
                            We offer bespoke admissions guidance, tutoring services for standardized tests, and career mapping.
                        </h3>
                    </div>
                </section>
                {/* 4th Section */}
                <section className="container-fluid p-0 position-relative">
                    <div className="container">
                        <div className="row mx-0">
                            <div className="col-md-12 mt-5">
                                <h2 className="text-primary fw-bold text-center">Our Services</h2>
                                <div className='colored-line line-center mb-0'></div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="row mx-0">
                                    <div className="col-md-7 py-lg-3 px-lg-5 rounded order-1 order-md-1">
                                        <h3 className="text-dark mb-4 text-start fw-bolder">Admissions</h3>
                                        <div className="leading-line d-flex">
                                            <div className="border-primary border border-3 leading-straight-line"></div>
                                            <div className="border-primary border border-3 leading-bullet"></div>
                                        </div>
                                        <p className="text-start mt-4">
                                            We help you secure admission in your dream institution through holistic profile development and effective storytelling via your application essays.
                                        </p>
                                        <div className="row mt-4">
                                            <div className="col-4 mb-4">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/student_LYz6fb8L7.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/student_LYz6fb8L7.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/student_LYz6fb8L7.png?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        Undergraduate
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-4">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/graduate_XTnT905hJ.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/graduate_XTnT905hJ.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/graduate_XTnT905hJ.png?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        Postgraduate
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-4">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/briefcase_e2AIbCBgSU.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/briefcase_e2AIbCBgSU.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/briefcase_e2AIbCBgSU.png?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text ms-2">
                                                        MBA
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 order-1 order-md-1 d-flex align-items-center mb-4">
                                        <div className="row col-md-12">
                                            <CustomImage imgCDN  className=""
                                                srcSetPaths={[
                                                    {path: `/creatives/395efd0a3740cbb60b7735209a7d3730_4syLnu7yY.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                    {path: `/creatives/395efd0a3740cbb60b7735209a7d3730_4syLnu7yY.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                ]}
                                                mainSrc={`/creatives/395efd0a3740cbb60b7735209a7d3730_4syLnu7yY.png?tr=h-280,r-8,f-png`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-7 py-lg-3 px-lg-5 rounded order-2 order-md-2">
                                        <h3 className="text-dark mb-4 text-start fw-bolder">Career Mapping</h3>
                                        <div className="leading-line d-flex">
                                            <div className="border-primary border border-3 leading-straight-line"></div>
                                            <div className="border-primary border border-3 leading-bullet"></div>
                                        </div>
                                        <p className="text-start mt-4">
                                            We analyze your aptitude, passions, and experience, vis-à-vis our global market insights to chart the optimum path for your career.
                                        </p>
                                        <div className="row mt-4">
                                            <div className="col-4 mb-4">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/career-choice_zlYqscbii.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/career-choice_zlYqscbii.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/career-choice_zlYqscbii.png?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        Career Advice
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-4 px-0">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/documents_z-YlNMx_x.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/documents_z-YlNMx_x.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/documents_z-YlNMx_x.png?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        Resume Review
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-4 px-0">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/accounting_TKHvv-Eqn.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/accounting_TKHvv-Eqn.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/accounting_TKHvv-Eqn.png?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        Financial Planning
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 order-2 order-md-1 d-flex align-items-center mb-4">
                                        <div className="row col-md-12">
                                            <CustomImage imgCDN  className=""
                                                srcSetPaths={[
                                                    {path: `/creatives/image__1__sl3X31CEH.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                    {path: `/creatives/image__1__sl3X31CEH.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                ]}
                                                mainSrc={`/creatives/image__1__sl3X31CEH.png?tr=h-280,r-8,f-png`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-7 py-lg-3 px-lg-5 rounded order-1 order-lg-1">
                                        <h3 className="text-dark mb-4 text-start fw-bolder">Standardized Tests</h3>
                                        <div className="leading-line d-flex">
                                            <div className="border-primary border border-3 leading-straight-line"></div>
                                            <div className="border-primary border border-3 leading-bullet"></div>
                                        </div>
                                        <p className="text-start mt-4 mb-3">
                                            Collegify uses data analytics to help students reach their true potential on standardized tests.
                                        </p>
                                        <ul className="d-flex fw-bold horizontal-list">
                                            <li className="d-flex"><MdDone className="material-icons text-success d-none d-md-block" fontSize="20px" /> 10K+ Questions</li>
                                            <li className="d-flex ms-2"><MdDone className="material-icons text-success d-none d-md-block" fontSize="20px" /> 20+ Mocks</li>
                                            <li className="d-flex ms-2"><MdDone className="material-icons text-success d-none d-md-block" fontSize="20px" /> 400+ Worksheets</li>
                                            <li className="d-flex ms-2"><MdDone className="material-icons text-success d-none d-md-block" fontSize="20px" /> 250+ Video Lessons</li>
                                        </ul>
                                        <div className="row mt-4 mx-0">
                                            <div className="col-4 mb-4 px-0">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/SAT-Books_WMEACyBx5.svg?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/SAT-Books_WMEACyBx5.svg?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/SAT-Books_WMEACyBx5.svg?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        Digital-SAT
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-4">
                                                <div className="">
                                                    <CustomImage imgCDN  className="services-img"
                                                        srcSetPaths={[
                                                            {path: `/creatives/ACT-Books_0j8NLbEx9.svg?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                            {path: `/creatives/ACT-Books_0j8NLbEx9.svg?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                        ]}
                                                        mainSrc={`/creatives/ACT-Books_0j8NLbEx9.svg?tr=h-280,r-8,f-png`} 
                                                    />
                                                    <p className="text-start fw-bold services-text">
                                                        ACT
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 order-2 order-lg-2 d-flex align-items-center mb-4">
                                        <div className="row col-md-12">
                                            <CustomImage imgCDN  className=""
                                                srcSetPaths={[
                                                    {path: `/creatives/image_W3E7ZQGAX.png?tr=h-280,r-8,f-avif`, type: 'image/avif'},
                                                    {path: `/creatives/image_W3E7ZQGAX.png?tr=h-280,r-8,f-auto`, type: 'image/webp'}
                                                ]}
                                                mainSrc={`/creatives/image_W3E7ZQGAX.png?tr=h-280,r-8,f-png`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid p-0 position-relative py-5 bg-light-yellow" id="Admissions">
                    <div className="row mx-0 mb-4">
                        <div className="col-md-12">
                            <h2 className="text-primary fw-bold text-center">Admissions</h2>
                            <div className='colored-line line-center'></div>
                        </div>
                    </div>
                    <div className="col-xxl-8 mx-auto admission-card-container">
                        <div className="row gx-4 mx-4 col-md-6 col-lg-12 col-12 mx-auto">
                            <div className="col-lg-4 mx-auto mb-3">
                                <div className="bg-white bs-lg card-content-padding br-lg admission-card-lg mb-4">
                                    <div className="card border-0">
                                        <h3 className="fw-bold mb-3 text-primary">Undergraduate</h3>
                                        <p className="ts-14">
                                            We hone and highlight your strengths in your profile and applications to your best-fit courses and colleges.
                                        </p>
                                    </div>
                                    <div className="row text-start">
                                        <div className="col-md-6 my-2">
                                            <div className="card border-0">
                                                <h5 className="mb-2 fs-md-7">
                                                    Early Advising
                                                </h5>
                                                <ul className="black-dots">
                                                    <li>
                                                        Grades 9 & 10
                                                    </li>
                                                    <li>
                                                        Board Selection
                                                    </li>
                                                    <li>
                                                        Academic Enrichment
                                                    </li>
                                                    <li>
                                                        Profile Building
                                                    </li>
                                                    <li>
                                                        Summer School applications
                                                    </li>
                                                    <li>
                                                        Financial & Career planning
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="card border-0">
                                                <h5 className="mb-2 fs-md-7">
                                                    Applications
                                                </h5>
                                                <ul className="black-dots">
                                                    <li>
                                                        Grades 11 & 12
                                                    </li>
                                                    <li>
                                                        Country-wise advice
                                                    </li>
                                                    <li>
                                                        Course & College selection
                                                    </li>
                                                    <li>
                                                        Application & Essay Guidance
                                                    </li>
                                                    <li>
                                                        Scholarships & Financial Aid
                                                    </li>
                                                    <li>
                                                        Interview Preparation
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mx-auto mb-3">
                                <div className="bg-white bs-lg card-content-padding br-lg admission-card-lg mb-4">
                                    <div className="card border-0">
                                        <h3 className="fw-bold mb-3 text-primary">Postgraduate</h3>
                                        <p className="ts-14">
                                            We create a roadmap for your masters considering your research interests, academic aspirations, and career goals.
                                        </p>
                                    </div>
                                    <div className="row text-start">
                                        <div className="col-md-6 my-2">
                                            <div className="card border-0">
                                                <h5 className="mb-2 fs-md-7">
                                                    Advisory
                                                </h5>
                                                <ul className="black-dots">
                                                    <li>
                                                        Country-wise advice
                                                    </li>
                                                    <li>
                                                        Resume Review
                                                    </li>
                                                    <li>
                                                        Profile building
                                                    </li>
                                                    <li>
                                                        College & Course Selection
                                                    </li>
                                                    <li>
                                                        Financial & Career planning
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="card border-0">
                                                <h5 className="mb-2 fs-md-7">
                                                    Applications
                                                </h5>
                                                <ul className="black-dots">
                                                    <li>
                                                        Course-specific guidance
                                                    </li>
                                                    <li>
                                                        Application & Essay Guidance
                                                    </li>
                                                    <li>
                                                        Scholarships & Financial Aid
                                                    </li>
                                                    <li>
                                                        Interview Preparation
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4 mx-auto mb-3">
                                <div className="bg-white bs-lg card-content-padding br-lg admission-card-lg mb-4">
                                    <div className="card border-0">
                                        <h3 className="fw-bold mb-3 text-primary">MBA</h3>
                                        <p className="ts-14">
                                            We analyze your professional trajectory vis-à-vis your career goals and help you craft applications tailored to your best-fit B-schools.
                                        </p>
                                    </div>
                                    <div className="row text-start">
                                        <div className="col-md-6 my-3">
                                            <div className="card border-0">
                                                <h5 className="mb-2 fs-md-7">
                                                    Advisory
                                                </h5>
                                                <ul className="black-dots">
                                                    <li>
                                                        Country-wise advice
                                                    </li>
                                                    <li>
                                                        Resume Review
                                                    </li>
                                                    <li>
                                                        Profile building
                                                    </li>
                                                    <li>
                                                        B-School Selection
                                                    </li>
                                                    <li>
                                                        Financial & Career planning
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-3">
                                            <div className="card border-0">
                                                <h5 className="mb-2 fs-md-7">
                                                    Applications
                                                </h5>
                                                <ul className="black-dots">
                                                    <li>
                                                        GRE/GMAT Preparation
                                                    </li>
                                                    <li>
                                                        Application & Essay Guidance
                                                    </li>
                                                    <li>
                                                        Scholarships & Financial Aid
                                                    </li>
                                                    <li>
                                                        Interview Preparation
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="">
                        <div className="container">
                            {/* Desktop */}
                            <div className="d-none d-lg-block d-lg-flex flex-center flex-wrap mt-3 horizontal-timeline-container admissions-landing-timeline">
                                <div className="flex-column m-2">
                                    <h5 className="mb-4 fs-md-7">Individualized Mentoring</h5>
                                    <div className="yellow-circle"></div>
                                </div>
                                <div className="flex-column m-2">
                                    <h5 className="mb-4 fs-md-7">Profile Building</h5>
                                    <div className="yellow-circle"></div>
                                </div>
                                <div className="flex-column m-2">
                                    <h5 className="mb-4 fs-md-7">Best-fit Universities</h5>
                                    <div className="yellow-circle"></div>
                                </div>

                                <div className="flex-column m-2">
                                    <h5 className="mb-4 fs-md-7">Essay &amp; Interview Prep</h5>
                                    <div className="yellow-circle"></div>
                                </div>
                                <div className="flex-column m-2">
                                    <h5 className="mb-4 fs-md-7">Successful Admission!</h5>
                                    <div className="yellow-circle active"></div>
                                </div>
                            </div>
                            {/* Mobile */}
                            <div className="flex-center">
                                <div className="why-collegify d-block d-lg-none admissions-inner-nav mt-5">
                                    <div className="admissions-inner-nav-item"><h5>Individualized Mentoring</h5></div>
                                    <div className="admissions-inner-nav-item"><h5>Profile Building</h5></div>
                                    <div className="admissions-inner-nav-item"><h5>Best Fit Universities</h5></div>
                                    <div className="admissions-inner-nav-item"><h5>Essay &amp; Interview Prep</h5></div>
                                    <div className="admissions-inner-nav-item active"><h5>Successfull Admission!</h5></div>
                                </div>
                            </div>
                            <div className="text-center my-4">
                                <h3 className="fs-3 mb-2">Come aboard the Collegify experience!</h3>
                            </div>
                            <div className="flex-center">
                            <a href={process.env.NEXT_PUBLIC_ADMISSION_URI}>

                                    <button className="btn btn-primary text-white btn-with-icon">
                                        Book Consultation
                                        <span className="btn-icon"><MdArrowForward className="material-icons" /></span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </section>
                    <svg className="position-absolute z-index-low bottom-0 start-0"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#f3f4f5" fillOpacity="1" d="M0,64L60,53.3C120,43,240,21,360,42.7C480,64,600,128,720,181.3C840,235,960,277,1080,282.7C1200,288,1320,256,1380,240L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                    </svg>
                </section>                              

                {/* 2nd section */}
                <section className="container">
                    <div>
                        {/* SAT */}
                        {
                            ComponentStatus.INTRO_SAT &&
                            <div className="row align-items-center mb-5">
                                <div className="col-md-7 pt-lg-5">
                                    <SATIndex className="landing-features-image--students" style={{ maxWidth: '100%' }} />
                                </div>
                                <div className="col-md-5 pt-2">
                                    <h2 className="text-primary">Digital-SAT —</h2>
                                    <h2 className="mb-4">A Better Way to Learn</h2>
                                    <h5 className="mb-4 font-weight-normal">
                                        Our e-learning platform provides you with the most efficient
                                        method of solving questions so that you can finally get those
                                        deserving points on the Digital-SAT.
                                    </h5>
                                    <Link href="/course/digital-sat">
                                        <a className="inline-link text-primary bold flex-row align-items-center">
                                            Know More
                                            <MdArrowForward fontSize="22px" className="ms-2" />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        }
                        {/* ACT */}
                        {
                            ComponentStatus.INTRO_ACT &&
                            <div className="row align-items-center">
                                <div className="col-md-7 order-1 order-md-2">
                                    <ACTIndex className="landing-features-image--parents" style={{ maxWidth: '100%' }} />
                                </div>
                                <div className="col-md-5 order-2 order-md-1">
                                    <h2 className="text-primary">ACT —</h2>
                                    <h2 className="mb-4">Get Personalized Guidance</h2>
                                    <h5 className="mb-4 font-weight-normal">
                                        Our comprehensive topic-by topic approach can help you identify
                                        and solve tricky questions on the ACT.
                                    </h5>
                                    <Link href="/course/act">
                                        <a className="inline-link text-primary bold flex-row align-items-center">
                                            Know More
                                            <MdArrowForward fontSize="22px" className="ms-2" />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                {/* Admission style Testimonials */}
                <section className="container-fluid p-0 mt-5 pb-5 padding-y-100 bg-light">
                    <div className="container">
                        <div className="row align-items-end justify-content-between m-0">
                            <div className="col-md-12 mx-auto">
                                <p className="fw-bold fs-4 text-center mt-5">
                                    Since 2011, Collegify has placed 7500+ students at the world's best universities.<br /> Our students have received over $40 million in scholarships and financial aid annually.
                                </p>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="card avatar-card">
                                    <div className="avatar">
                                        <CustomImage srcSetPaths={[{path: '/students/ananya-gupta.jpeg?tr=w-92,f-avif', type: 'image/avif'},{path: '/students/ananya-gupta.jpeg?tr=w-92,f-auto', type: 'image/webp'}]}
                                            mainSrc="/students/ananya-gupta.jpeg?tr=w-92" alt="Ananya Gupta" width="92px" height="92px" imgCDN />
                                    </div>
                                    <div className="card-body text-center">
                                        <h5>Ananya Gupta</h5>
                                        {/* <small className="fw-bold">
                                            INSEAD className of 2023
                                        </small> */}
                                        <p className="mt-4">
                                            It has been a great pleasure to work with the team Collegify. Their Career Guidance
                                            along with profile building before the applications and constant help with
                                            application process and systematic approach to managing the applications deadlines
                                            has been impeccable. If you are looking to pursue your masters abroad, I highly
                                            recommend team {process.env.NEXT_PUBLIC_APP_NAME}!
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <CustomImage srcSetPaths={[{path: '/university-logos/insead-rect.png?tr=h-80,f-avif', type: 'image/avif'},{path: '/university-logos/insead-rect.png?tr=h-80,f-auto', type: 'image/webp'}]}
                                        mainSrc="/university-logos/insead-rect.png?tr=h-80,f-png" alt="insead University Logo" height="80px" width="120px" imgCDN  className='mx-auto' />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4 order-1 order-lg-0">
                                <div className="mb-4">
                                    <div className="card avatar-card">
                                        <div className="avatar">
                                            <CustomImage srcSetPaths={[{path: '/students/shreya-tuli.jpg?tr=w-92,f-avif', type: 'image/avif'},{path: '/students/shreya-tuli.jpg?tr=w-92,f-auto', type: 'image/webp'}]}
                                                mainSrc="/students/shreya-tuli.jpg?tr=w-92,f-png" alt="shreya-tuli" width="92px" height="92px" imgCDN />
                                        </div>
                                        <div className="card-body text-center">
                                            <h5>Shreya Tuli</h5>
                                            <p className="mt-4">
                                                The {process.env.NEXT_PUBLIC_APP_NAME} team&#39;s assistance for choosing the right fit with an unbiased, logical,
                                                and data-driven approach has been the guiding factor for all my decisions. They
                                                made the application process sorted and unchallenging; most of all, they taught me
                                                important life lessons and gave me a vision. I&#39;m proud to be continuing {process.env.NEXT_PUBLIC_APP_NAME}'s legacy of producing Cornell TATA Scholars. I recommend them without
                                                reservation. I couldn&#39;t be more grateful!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <CustomImage srcSetPaths={[{path: '/university-logos/cornell-color.png?tr=h-120,f-avif', type: 'image/avif'},{path: '/university-logos/cornell-color.png?tr=h-120,f-auto', type: 'image/webp'}]}
                                            mainSrc="/university-logos/cornell-color.png?tr=h-120,f-png" alt="Cornell University Logo" width="180px" height="180px" imgCDN className="mx-auto" />
                                    </div>
                                    </div>
                                <div className="d-none d-md-block" style={{height: 100}}></div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="card avatar-card">
                                    <div className="avatar">
                                        <CustomImage srcSetPaths={[{path: '/students/seher-taneja.jpeg?tr=w-92,f-avif', type: 'image/avif'},{path: '/students/seher-taneja.jpeg?tr=w-92,f-auto', type: 'image/webp'}]}
                                            mainSrc="/students/seher-taneja.jpeg?tr=w-92" alt="Seher Taneja" width="92px" height="92px" imgCDN />
                                    </div>
                                    <div className="card-body text-center">
                                        <h5>Seher Taneja</h5>
                                        <p className="mt-4">
                                            My heartfelt gratitude to team {process.env.NEXT_PUBLIC_APP_NAME} for standing by our side, guiding us and motivating us
                                            throughout this journey. We are overwhelmed by your dedication and effort. Seher has found a true
                                            mentor to share all her happiness, fears, and goals with. The team’s patient demeanour won her over.
                                            God bless team {process.env.NEXT_PUBLIC_APP_NAME}! <br/><span className="fw-bold">Dr. Dipali Taneja</span> (Seher’s mother)
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center">
                                <CustomImage srcSetPaths={[{path: '/university-logos/penn.png?tr=h-80,f-avif', type: 'image/avif'},{path: '/university-logos/penn.png?tr=h-80,f-auto', type: 'image/webp'}]}
                                    mainSrc="/university-logos/penn.png?tr=h-80,f-png" alt="penn University Logo" width="120" height="120" imgCDN className="mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                

                {/* Andres Video */}
                <section className='certificate-section my-0 py-5'>
                    <div className="container">
                        <div className="intro-content">
                            <h2 className='text-center fw-bold mb-4 text-primary'>
                                {process.env.NEXT_PUBLIC_APP_NAME} Numbers
                            </h2>
                            <div className="colored-line line-center"></div>
                        </div>
                        <div className="intro-content big mt-5">
                            <div className="ak-card">
                                <div className="d-flex flex-column flex-md-row  gap-5 justify-content-around align-items-center">
                                    <div className="item">
                                        <h3 className='text-primary mb-3'>$40M+</h3>
                                        <p>in scholarships at the most prestigious universities</p>
                                    </div>
                                    <div className="item">
                                        <h3 className='text-primary mb-3'>7500+</h3>
                                        <p>Students admitted into competitive UG, PG and MBA programs</p>
                                    </div>
                                    <div className="item">
                                        <h3 className='text-primary mb-3'>400+</h3>
                                        <p>Alumni across top global institutions.</p>
                                    </div>
                                    <div className="item">
                                        <h3 className='text-primary mb-3'>100+</h3>
                                        <p>Bespoke hours, preparing students for their study abroad journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row">
                        <div className='text-center intro-content my-5'>
                            <h2 className="fw-light">Over <span className='bold text-primary'>50,000 students across 10 countries</span> have used our analytics to improve their scores!</h2>
                        </div>
                    </div>
                </section>
                
                <section className='container mt-5 mb-4'>
                    <div className="row d-flex justify-content-center">
                        <div className='col-md-3 col-6 mb-5'>
                            <svg className='feature-icon'>
                                <use href='/images/thumbnails/sprite.svg#icon-bulb' />
                            </svg>
                            <p className='feature-text text-start'>
                                <b>Personalized practice {/*suggestions*/}</b>
                                <br /> Data analytics to monitor your performance
                            </p>
                        </div>
                        <div className='col-md-3 col-6 mb-5'>
                            <svg className='feature-icon'>
                                <use href='/images/thumbnails/sprite.svg#icon-badge' />
                            </svg>
                            <p className='feature-text text-start'>
                                <b>Know where you stand</b>
                                <br /> Tailored insights and learning recommendations
                            </p>
                        </div>
                        <div className='col-md-3 col-6 mb-5'>
                            <svg className='feature-icon'>
                                <use href='/images/thumbnails/sprite.svg#icon-topic' />
                            </svg>
                            <p className='feature-text text-start'>
                                <b>Expert guidance</b>
                                <br />Experienced tutors to get you exam-ready
                            </p>
                        </div>
                        <div className='col-md-3 col-6 mb-5'>
                            <svg className='feature-icon'>
                                <use href='/images/thumbnails/sprite.svg#icon-presentation' />
                            </svg>
                            <p className='feature-text text-start'>
                                <b>Strategic advice</b>
                                <br />Tips and tricks to minimize mistakes
                            </p>
                        </div>
                    </div>
                </section>
                {
                ComponentStatus.STUDENT_TESTIMONIAL &&
                <section className="pt-5">
                    <div className="container text-center">
                        <h2 className="text-primary fw-bold">Testimonials</h2>
                        <div className='colored-line line-center mb-4'></div>
                        <h4 className="fw-600 mb-3 text-center">
                            Akshat Tiwari on his journey with Collegify
                            <br /> <span className="text-primary">SAT: 1560</span>
                        </h4>

                        {/* Video Testimonial */}
                        <div className="mb-4">
                            <div className="lazyframe video-pricing position-relative"
                                id="pricing-video"
                                data-vendor=""
                                data-title=""
                                data-thumbnail={process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT + '/static/video-poster.jpg?tr=f-auto,dpr-0.5,fo-center'}
                                data-src="https://iframe.mediadelivery.net/embed/8152/58cdb220-6970-4889-9f3f-3c05c3c79114?autoplay=false&preload=false"
                                data-ratio="16:9"
                                data-initinview="false"
                                data-autoplay="false"
                            />
                        </div>
                    </div>
                </section>
                }
                {/* Media Coverage */}
                <section className="media-coverage mb-4">
                    <div className="py-3 px-3 px-md-0 container text-center">
                        <h2 className="text-primary fw-bold">Media Highlights</h2>
                        <div className='colored-line line-center mb-4'></div>
                        <h4 className="fw-600 mb-3">Content contributor in Economic Times, India Today, The Hindu, and Business Standard</h4>
                        <div className="gallery d-none d-lg-grid">
                            <figure className='gallery__item gallery__item--1'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-8.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-8.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-8.jpg" alt="Media 1" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--2'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-2.jpg', type: 'image/avif'},{path: '/media-highlights/media-2.jpg', type: 'image/webp'}]}
                                            mainSrc="/media-highlights/media-2.jpg" alt="Media 2" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--3'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-9.jpg', type: 'image/avif'},{path: '/media-highlights/media-9.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-9.jpg?tr=w-300,f-png" alt="Media 3" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--4'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-4.jpg', type: 'image/avif'},{path: '/media-highlights/media-4.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-4.jpg?tr=w-250,f-png" alt="Media 4" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--5'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-23.jpg', type: 'image/avif'},{path: '/media-highlights/media-23.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-23.jpg?tr=w-350,f-png" alt="Media 5" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--6'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-6.jpg', type: 'image/avif'},{path: '/media-highlights/media-6.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-6.jpg?tr=w-350,f-png" alt="Media 6" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--7'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-7.jpg', type: 'image/avif'},{path: '/media-highlights/media-7.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-7.jpg?tr=w-350,f-png" alt="Media 7" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--8'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-7.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-7.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-7.jpg" alt="Media 8" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--9'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-4.jpeg', type: 'image/avif'},{path: '/media-highlights/media-article-4.jpeg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-4.jpeg?tr=w-350,f-png" alt="Media 9" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--10'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-3.png', type: 'image/avif'},{path: '/media-highlights/media-article-3.png?tr=w-350,f-auto', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-3.png?tr=w-350,f-png" alt="Media 10" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--11'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-2.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-2.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-2.jpg?tr=w-350,f-png" alt="Media 11" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--12'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-1.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-1.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-1.jpg?tr=w-350,f-png" alt="Media 12" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                        </div>
                        <div className="media-article-grid d-block d-lg-none">
                        <figure className='gallery__item gallery__item--1'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-8.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-8.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-8.jpg" alt="Media 1" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--2'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-2.jpg', type: 'image/avif'},{path: '/media-highlights/media-2.jpg', type: 'image/webp'}]}
                                            mainSrc="/media-highlights/media-2.jpg" alt="Media 2" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--3'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-9.jpg', type: 'image/avif'},{path: '/media-highlights/media-9.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-9.jpg?tr=w-300,f-png" alt="Media 3" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--4'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-4.jpg', type: 'image/avif'},{path: '/media-highlights/media-4.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-4.jpg?tr=w-250,f-png" alt="Media 4" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--5'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-23.jpg', type: 'image/avif'},{path: '/media-highlights/media-23.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-23.jpg?tr=w-350,f-png" alt="Media 5" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--6'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-6.jpg', type: 'image/avif'},{path: '/media-highlights/media-6.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-6.jpg?tr=w-350,f-png" alt="Media 6" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--7'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-7.jpg', type: 'image/avif'},{path: '/media-highlights/media-7.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-7.jpg?tr=w-350,f-png" alt="Media 7" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--8'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-7.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-7.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-7.jpg" alt="Media 8" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--9'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-4.jpeg', type: 'image/avif'},{path: '/media-highlights/media-article-4.jpeg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-4.jpeg?tr=w-350,f-png" alt="Media 9" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--10'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-3.png', type: 'image/avif'},{path: '/media-highlights/media-article-3.png?tr=w-350,f-auto', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-3.png?tr=w-350,f-png" alt="Media 10" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--11'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-2.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-2.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-2.jpg?tr=w-350,f-png" alt="Media 11" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                            <figure className='gallery__item gallery__item--12'>
                                <Zoom>
                                    <CustomImage srcSetPaths={[{path: '/media-highlights/media-article-1.jpg', type: 'image/avif'},{path: '/media-highlights/media-article-1.jpg', type: 'image/webp'}]}
                                        mainSrc="/media-highlights/media-article-1.jpg?tr=w-350,f-png" alt="Media 12" height="100%" width="100%" imgCDN className="gallery__img"  />
                                </Zoom>
                            </figure>
                        </div>
                    </div>
                </section>
                {locationDetails.country_code === "IN" && ComponentStatus.ADMISSION && (
                    <section className="admissions-cta mb-4">
                        <div className="flex-center">
                            <div className="card educator-card admissions-cta-card my-md-5 my-0">
                                <div className="card-body text-center">
                                    <h2 className="mb-4">
                                        7500+ students found their{" "}
                                        <br className="d-none d-md-block" /> dream university with
                                        us.
                                    </h2>
                                    <h4 className="mb-4">Where are you headed?</h4>
                                    <div className="mb-4">
                                        <a href={process.env.NEXT_PUBLIC_ADMISSION_URI}>
                                            <button className="btn btn-solid-normal --secondary btn-with-icon text-nowrap mx-auto">
                                                View Admissions Services
                                                <span className="btn-icon --secondary">
                                                    <MdArrowForward fontSize="22px" />
                                                </span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {
                    ComponentStatus.CHOICE &&
                    <WhyChoseCollegify />
                }
        </Layout>
        )
    }
}
