export default function SiteLoader() {
  return (
    <div className="loader" aria-label="loader">
    <div className="loader-inner">
      <div className="book">
        <div className="back"></div>
        <div className="page5"></div>
        <div className="page4"></div>
        <div className="page3"></div>
        <div className="page2"></div>
        <div className="page1"></div>
        <div className="front"></div>
      </div>
    </div>
  </div>
  )
}